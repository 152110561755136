export const PRICE = `{
  netPrice
  grossPrice
  originalPrice
  taxes
}`;

export const ORDER_ITEMS = `{
  id
  itemType
  orderPricing ${PRICE}
  deliveryArtifactId
  blockchainTransactionId
  product {
    id
    name
    type
    tokenFactoryName
    onChainId
  }
}`;

export const ORDER = `{
  id
  hash
  items ${ORDER_ITEMS}
  status
  expirationDate
  storeCurrency
  orderCurrency
  storeCurrencySymbol
  storeTotalPricing ${PRICE}
  orderTotalPricing ${PRICE}
  error
}`;
