@if (initStorageCheck) {
  <div class="d-flex justify-content-end f-size-14 text-white text-nowrap">
    @if (!isBalanceHidden) {
      <div ultraAutotestAttribute="wallet-balance-value">
        {{ walletBalance | ultraCurrency }}
      </div>
    } @else {
      <div
        i18n
        class="f-size-14 text-white-alpha-7 text-end text-capitalize"
        ultraAutotestAttribute="wallet-balance-unhide">
        Show wallet balance
      </div>
    }
    <i
      class="icon cursor-pointer ms-2"
      (click)="toggleBalance()"
      [ngClass]="{
        'icon-eye-off': isBalanceHidden,
        'icon-eye-on': !isBalanceHidden
      }"
      [ultraAutotestAttribute]="'wallet-balance--' + isBalanceHidden"></i>
  </div>
}
