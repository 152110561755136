import { Inject, Injectable } from '@angular/core';
import { catchError, defer, from, map, Observable, of, tap, throwError, timeout } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import {
  AuthenticatorErrorCode,
  IApiResult,
  IAuthenticatorService,
  ICreateWalletResponse,
  IGetAccountsResponse,
  IGetBlockchainIdsResponse,
  ISignMessageResponse,
  ISignTransactionResponse,
  ITransactionAction,
} from '../../services/authenticator';

import {
  DfusePushTransactionHeaderName,
  DfusePushTransactionHeaderValue,
} from './enums/dfuse-push-transaction-header.enum';
import { AuthenticatorError } from './models/authenticator-error';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatorService implements IAuthenticatorService {
  readonly requestTimeout = 10_000;
  get authenticator() {
    return window['ultraos'].authenticator;
  }

  constructor(@Inject(APP_CONFIG) private environment: IEnvironment) {}

  create(clientId: string, nodeOsApiUrl: string = this.environment.blockchainUrl): Observable<boolean> {
    return this.toObservable<boolean>(() =>
      this.authenticator.create({ client: clientId, nodeosApiUrl: nodeOsApiUrl }),
    ).pipe(tap(() => this.setEventHandlers()));
  }

  signMessage(blockchainId: string, message: string, uniqueId: string = uuidv4()): Observable<ISignMessageResponse> {
    return this.toObservable<ISignMessageResponse>(() =>
      this.authenticator.signMessage(uniqueId, blockchainId, message),
    );
  }

  signTransaction(
    uniqueId: string,
    actions: Array<ITransactionAction>,
    headers: { [name: string]: string } = {
      [DfusePushTransactionHeaderName.EOS_PUSH_GUARANTEE]: DfusePushTransactionHeaderValue.IN_BLOCK,
    },
    signingBlockchainIds: string[] = [],
    signOnly: boolean = false,
  ): Observable<ISignTransactionResponse> {
    return this.toObservable<ISignTransactionResponse>(() =>
      this.authenticator.signTransaction(uniqueId, actions, headers, signingBlockchainIds, signOnly),
    );
  }

  abortTransaction(uniqueId: string): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.abortTransaction(uniqueId));
  }

  clearWallet(): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.clearWallet());
  }

  createWallet(): Observable<ICreateWalletResponse> {
    return this.toObservable<ICreateWalletResponse>(() => this.authenticator.createWallet());
  }

  hasKeys(blockchainId: string): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.hasKeys(blockchainId));
  }

  linkAccount(publicKey: string, blockchainId: string): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.linkAccount(publicKey, blockchainId));
  }

  lock(): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.lock());
  }

  unlock(symmetricKey: string): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.unlock(symmetricKey));
  }

  isUnlocked(): Observable<boolean> {
    return this.getAccounts().pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  getAccounts(): Observable<IGetAccountsResponse> {
    return this.toObservable<IGetAccountsResponse>(() => this.authenticator.getAccounts());
  }

  hasWallet(): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.hasWallet()).pipe(catchError(() => of(false)));
  }

  importKeyForWallet(blockchainId: string, privateKey: string): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.importKeyForWallet(blockchainId, privateKey));
  }

  getBlockchainIDs(publicKey: string): Observable<Array<IGetBlockchainIdsResponse>> {
    return this.toObservable(() => this.authenticator.getBlockchainIDs(publicKey));
  }

  getBalance(blockchainId: string): Observable<number> {
    blockchainId = blockchainId?.split('@')[0];
    return this.toObservable<number>(() => this.authenticator.getBalance(blockchainId));
  }

  privateKeyToPublic(privateKey: string): Observable<string> {
    return this.toObservable<string>(() => this.authenticator.privateKeyToPublic(privateKey));
  }

  generatePrivateKey(): Observable<string> {
    return this.toObservable<string>(() => this.authenticator.generatePrivateKey());
  }

  isInstanceCreated(clientId: string): Observable<boolean> {
    return this.toObservable<boolean>(() => this.authenticator.isInstanceCreated(clientId));
  }

  private toObservable<T>(apiResultPromise: () => Promise<IApiResult<T>>) {
    return defer(() => from(apiResultPromise())).pipe(
      map((result) => {
        if (result.success === true) {
          return result.data;
        }
        throw new AuthenticatorError(result.data);
      }),
      // Authenticator did not respond within the specified timeout interval
      timeout({
        each: this.requestTimeout,
        with: () => throwError(() => new AuthenticatorError(AuthenticatorErrorCode.REQUEST_TIMEOUT)),
      }),
    );
  }

  private setEventHandlers() {
    // In this method we can add some logic to ask user confirmation before perform a transaction
    const handleSignTransactionEvent = (payload, callback) => callback(payload.unique_id);
    this.authenticator.addEventListener('open_transaction_popup', handleSignTransactionEvent);
    this.authenticator.addEventListener('open_sign_message_popup', handleSignTransactionEvent);
  }
}
