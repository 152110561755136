import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ultraCurrency',
})
export class UltraCurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: number | string, symbol = 'UOS'): string | null {
    // validate is a valid number
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    if (typeof numberValue !== 'number' || Number.isNaN(numberValue)) {
      return null;
    }
    // format the number in local format
    const [integer, decimal] = String(value).split('.');
    const formattedInteger = formatNumber(Number(integer), this.locale);
    // make sure the decimal is 2 digits
    let formattedDecimal = decimal ? decimal.replace(/0+$/, '') : '';
    formattedDecimal = formattedDecimal.length < 2 ? formattedDecimal.padEnd(2, '0') : formattedDecimal;
    // add symbol
    let formattedValue = `${formattedInteger}.${formattedDecimal}`;
    formattedValue = symbol === 'UOS' ? `ᕫ ${formattedValue}` : `${formattedValue} ${symbol}`;
    return formattedValue;
  }
}
