import { gql } from 'apollo-angular';

import { ORDER } from '../shared/order';

export const CREATE_ORDER = gql`
  mutation createOrder($orderToSave: OrderToSave!) {
    createOrder(order: $orderToSave) ${ORDER}
  }
`;

export const REFRESH_ORDER = gql`
  mutation refreshOrder($orderId: String!) {
    refreshOrder(orderId: $orderId) ${ORDER}
  }
`;

export const APPROVE_ORDER = gql`
  mutation approveOrder($orderId: String!, $captcha: Captcha) {
    approveOrder(orderId: $orderId, captcha: $captcha) ${ORDER}
  }
`;

export const REGISTER_CAPTCHA = gql`
  mutation registerCaptcha {
    registerCaptcha {
      gt
      challenge
      newCaptcha
      online
    }
  }
`;
