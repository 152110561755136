import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGraphQLService, ApolloClientName } from '@ultra/core/graphql';
import { CurrencyType, IAddressData } from '@ultra/core/models';

import { ApiSharedGqlConfig } from '../../graphql-client/api-shared-gql.config';
import { IOrderItem, IOrderToSave, Order } from '../../models';
import { IGeetestCaptchaValidationData, IGeetestData } from '../../services/captcha';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private apiService: ApiGraphQLService) {}

  createOrder(
    items: IOrderItem[],
    currency: CurrencyType | string = CurrencyType.UOS,
    billingInformationId?: string,
    billingAddress?: Omit<IAddressData, 'country'>,
  ): Observable<Order> {
    const orderToSave: IOrderToSave = {
      currency,
      items,
      billingInformationId,
      billingAddress,
    };
    return this.apiService
      .createMutation(ApiSharedGqlConfig.CREATE_ORDER, { orderToSave }, { apolloClient: ApolloClientName.ORDER })
      .pipe(map((response) => new Order(response?.data?.createOrder)));
  }

  orderStatusUpdated(id: string): Observable<Order> {
    return this.apiService
      .getSubscription(ApiSharedGqlConfig.ORDER_STATUS_SUB, { id }, { apolloClient: ApolloClientName.ORDER })
      .pipe(map((response) => new Order(response?.data?.updateOrderStatus)));
  }

  getOrder(orderId: string): Observable<Order> {
    return this.apiService
      .createMutation(ApiSharedGqlConfig.GET_ORDER, { orderId }, { apolloClient: ApolloClientName.ORDER })
      .pipe(map((response) => new Order(response?.data?.getOrder)));
  }

  refreshOrder(orderId: string): Observable<Order> {
    return this.apiService
      .createMutation(ApiSharedGqlConfig.REFRESH_ORDER, { orderId }, { apolloClient: ApolloClientName.ORDER })
      .pipe(map((response) => new Order(response?.data?.refreshOrder)));
  }

  approveOrder(orderId: string, captcha: IGeetestCaptchaValidationData): Observable<Order> {
    return this.apiService
      .createMutation(ApiSharedGqlConfig.APPROVE_ORDER, { orderId, captcha }, { apolloClient: ApolloClientName.ORDER })
      .pipe(map((response) => new Order(response?.data?.approveOrder)));
  }

  registerCaptchaForOrderPurchase(): Observable<IGeetestData> {
    return this.apiService
      .createMutation(ApiSharedGqlConfig.REGISTER_CAPTCHA, null, {
        apolloClient: ApolloClientName.ORDER,
      })
      .pipe(map((response) => response?.data?.registerCaptcha));
  }
}
