import {
  CancelResellUniqParams,
  IBuyUniqParams,
  ITransactionAction,
  ITransferUniqParams,
  ResellUniqParams,
} from '../interfaces';

export class BlockchainTransactionBuilder {
  private readonly transactionAction: ITransactionAction;

  constructor() {
    this.transactionAction = {
      action: 'transfer',
      data: {},
      authorizations: [],
      contract: null,
    };
  }

  contract(contractName: string): BlockchainTransactionBuilder {
    this.transactionAction.contract = contractName;
    return this;
  }

  action(actionName: string): BlockchainTransactionBuilder {
    this.transactionAction.action = actionName;
    return this;
  }

  authorizationForTransferAction(blockchainId): BlockchainTransactionBuilder {
    this.transactionAction.authorizations = [`${blockchainId}@active`];
    return this;
  }

  dataForTransferAction(
    blockchainFrom: string,
    blockchainTo: string,
    quantity: number,
    memo: string,
    token = 'UOS',
    tokenDecimals = 8,
  ): BlockchainTransactionBuilder {
    this.transactionAction.data = {
      from: blockchainFrom,
      to: blockchainTo,
      quantity: `${quantity.toFixed(tokenDecimals)} ${token}`,
      memo,
    };
    return this;
  }

  dataForResellAction({
    seller,
    token_id,
    price,
    uniq,
    memo = '',
    promoter_basis_point,
  }: ResellUniqParams): BlockchainTransactionBuilder {
    const resell = {
      seller,
      token_id,
      memo,
      price: `${price.toFixed(8)} UOS`,
      promoter_basis_point,
    };
    this.transactionAction.data = {
      resell,
      uniq,
    };
    return this;
  }

  dataForCancelResellAction({ token_id, memo, uniq }: CancelResellUniqParams): BlockchainTransactionBuilder {
    const cancelresell = {
      token_id,
      memo,
    };
    this.transactionAction.data = {
      cancelresell,
      uniq,
    };
    return this;
  }

  dataForBuyAction(
    tokenId: string,
    buyer: string,
    receiver: string,
    maxPrice: number | string,
    memo?: string,
    promoterId?: string,
  ) {
    const buy: IBuyUniqParams = {
      token_id: tokenId,
      buyer,
      receiver,
      max_price: `${Number(maxPrice).toFixed(8)} UOS`,
      memo: memo || '',
      promoter_id: promoterId || null,
    };
    this.transactionAction.data = { buy };
    return this;
  }

  dataForTransferUniq(from: string, to: string, tokenIds: string[], memo: string) {
    const transfer: ITransferUniqParams = {
      from,
      to,
      token_ids: tokenIds,
      memo: memo ?? '',
    };
    this.transactionAction.data = { transfer };
    return this;
  }

  dataForNewAccountAction(creator: string, publicKey: string, maxPayment: number): BlockchainTransactionBuilder {
    this.transactionAction.data = {
      creator: creator,
      owner: {
        threshold: 1,
        keys: [{ key: publicKey, weight: 1 }],
        accounts: [],
        waits: [],
      },
      active: {
        threshold: 1,
        keys: [{ key: publicKey, weight: 1 }],
        accounts: [],
        waits: [],
      },
      max_payment: `${maxPayment.toFixed(8)} UOS`,
    };
    return this;
  }

  build() {
    return this.transactionAction;
  }
}
