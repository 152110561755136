import { CurrencyType, IAddressData } from '@ultra/core/models';

import { TokenFOrderStatus } from '../../token-factory/interfaces/order.interface';

export enum OrderItemType {
  GAMES = 'GAMES',
  UNIQ = 'UNIQ',
}

export interface IOrderItem {
  tokenFactoryId: string;
  quantity: number;
  generateTokenImmediately?: boolean;
  itemType?: OrderItemType;
}

export interface IOrderToSave {
  items: IOrderItem[];
  currency: CurrencyType | string;
  billingInformationId?: string;
  billingAddress?: Omit<IAddressData, 'country'>;
}

export interface IOrderPrice {
  netPrice?: number;
  grossPrice: number;
  taxes?: number;
  originalPrice?: number;
}

export enum OrderErrorType {
  MARKET_FLUCTUATION = 'MARKET_FLUCTUATION',
  UOS_SALE_FAILED = 'UOS_SALE_FAILED',
  MINTING_FAILED = 'MINTING_FAILED',
  ACCOUNT_MINTING_LIMIT_FAILED = 'ACCOUNT_MINTING_LIMIT_FAILED',
  EXCEED_MAX_MINTABLE_TOKENS = 'EXCEED_MAX_MINTABLE_TOKENS',
  OTHER = 'OTHER',
}

export interface IOrder {
  id: string;
  hash: string;
  status: TokenFOrderStatus;
  storeCurrency: CurrencyType;
  orderCurrency: CurrencyType;
  storeTotalPricing: IOrderPrice;
  orderTotalPricing: IOrderPrice;
  items?: IOrderItems[];
  error?: OrderErrorType;
  storeCurrencySymbol?: string;
  expirationDate?: Date;
}

export interface IOrderProduct {
  id: string;
  name: string;
  tokenFactoryName?: string;
  price?: number;
  imageUrl?: string;
  type?: string;
  onChainId?: string;
}

export interface IOrderItems {
  id: string;
  itemType?: 'GAMES' | 'UNIQ';
  orderPricing?: IOrderPrice;
  deliveryArtifactId?: string;
  blockchainTransactionId?: string;
  product: IOrderProduct;
}
