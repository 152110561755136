import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subscriber } from 'rxjs';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { LicenseActivationResponse, LicenseResponse, RenewRoutineEventResponse } from '../../models';
import { IDimension, IDimensionApp } from '../../models/dimension/interfaces/dimension.interface';
import { WINDOW } from '../../providers/window.provider';

import { UltraosSettingKey } from './enums/ultraos.enum';
import { IDownloadRepository, RepositoryStates } from './interfaces/download-repository.interface';
import { StorageEvent } from './interfaces/ultraos-api.interface';
import { ultOSMock } from './mocks';

export type StorageType = 'persistent' | 'session';

/*
 * Wrapper for client's UltraOS API
 */
@Injectable({ providedIn: 'root' })
export class UltraosApiService {
  constructor(
    @Inject(APP_CONFIG) private environment: IEnvironment,
    @Inject(WINDOW) private window: Window,
  ) {}
  get ultraos() {
    return this.window['ultraos'] || (this.environment.featureFlags.mockUltraOs ? ultOSMock : this.window['ultraos']); // to avoid ts errors
  }

  launch(data: IDownloadRepository): Observable<boolean> {
    return new Observable((subject) => {
      this.ultraos.launch(`${data.repositoryId}${data.branch.toLowerCase()}`, () => {
        subject.next(true);
        subject.complete();
      });
    });
  }

  isRepositoryInstalled(
    repositoryId: string,
    branch: string,
    parentRepositoryId: string,
  ): Observable<{ [key: string]: RepositoryStates }> {
    return new Observable((subject) => {
      this.ultraos.game.isRepositoryInstalled(repositoryId, branch, parentRepositoryId, (result) => {
        subject.next(JSON.parse(result));
        subject.complete();
      });
    });
  }

  onCommandLineAddListener(): Observable<string> {
    return new Observable((subject) => {
      this.ultraos.onCommandLine.addListener((result) => {
        subject.next(result.value);
      });
    });
  }

  checkGamesPresenceByPath(gameIds: string[], path: string): Observable<{ [key: string]: string }> {
    return new Observable((subject) => {
      this.ultraos.checkGamesPresenceByPath(gameIds, path, (result) => {
        subject.next(JSON.parse(result));
        subject.complete();
      });
    });
  }

  public getDefaultDownloadGamesPath(): Observable<string> {
    return new Observable((subject) => {
      this.ultraos.game.getDefaultGamesPath((result) => {
        subject.next(result);
        subject.complete();
      });
    });
  }

  public setDefaultGamesPath(path: string | number): Observable<string> {
    return new Observable((subject) => {
      this.ultraos.game.setDefaultGamesPath(path, (result: string) => {
        subject.next(result);
        subject.complete();
      });
    });
  }
  /**
   * Opens a dialog to select a folder.
   * @returns path of the selected folder
   */
  public selectDownloadGamesPath(): Observable<string> {
    return new Observable((subject: Subscriber<string>) => {
      this.ultraos.game.selectFolder((result: string) => {
        subject.next(result);
        subject.complete();
      });
    });
  }

  componentVersion(repositoryId: string, branch: string, parentRepositoryId: string): Observable<string> {
    return new Observable((subject) => {
      this.ultraos.game.getRepositoryInstalledVersion(repositoryId, branch, parentRepositoryId, (res: string) => {
        subject.next(res);
        subject.complete();
      });
    });
  }

  getCurrentDeviceName(): Observable<string> {
    return new Observable((subject) => {
      this.ultraos.getDeviceName((name: string) => {
        subject.next(name);
        subject.complete();
      });
    });
  }

  getUltraClientVersion(): Observable<string> {
    if (this.ultraos?.getUltraClientVersion) {
      return new Observable((subject) => {
        this.ultraos.getUltraClientVersion((name: string) => {
          subject.next(name);
          subject.complete();
        });
      });
    }

    return of(null);
  }

  LeftPannelOrderBottomWrapper() {
    this.ultraos.LeftPannelOrderBottom();
  }

  showBrowserBarWrapper(app: IDimensionApp): void {
    this.ultraos.showBrowserBar(app.showBrowserBar);
  }

  openNewTabWrapper(app: IDimensionApp, path = ''): void {
    const jsonData = {
      iconApp: app.iconApp,
      iconDimension: app.iconDimension,
      url: app.url + path,
      showBrowserBar: app.showBrowserBar,
      showNewTabButton: app.showNewTabButton,
    };
    this.ultraos.openNewTab(JSON.stringify(jsonData));

    this.showBrowserBarWrapper(app);
  }

  getStorageData(key: string, storageType: StorageType = 'persistent'): Observable<any> {
    if (this.globalStoreAccessible()) {
      return new Observable((subject) => {
        const storage = this.getStorageObject(storageType);
        storage.get(key, function (result) {
          if (result) {
            const parseResult = typeof result === 'string' ? JSON.parse(result) : result;
            subject.next(parseResult);
          } else {
            subject.next(null);
          }
          subject.complete();
        });
      });
    }
    return of(null);
  }

  setStorageData(key: string, value: any, storageType: StorageType = 'persistent'): Observable<void> {
    return new Observable((subject) => {
      if (!this.globalStoreAccessible()) {
        subject.error(new Error('UltraOs API is not available'));
        return;
      }
      const storage = this.getStorageObject(storageType);
      storage.set(key, JSON.stringify(value), () => {
        subject.next();
        subject.complete();
      });
    });
  }

  removeStorageData(key: string, storageType: StorageType = 'persistent'): Observable<void> {
    return new Observable((subject) => {
      if (!this.globalStoreAccessible()) {
        subject.error(new Error('UltraOs API is not available'));
        return;
      }
      const storage = this.getStorageObject(storageType);
      const method = storageType === 'persistent' ? 'removeKey' : 'remove';
      storage[method](key, () => {
        subject.next();
        subject.complete();
      });
    });
  }

  openTabGroupWrapper(app: IDimensionApp, groupId: string, path = ''): void {
    this.ultraos.openTabGroup(groupId, app.url + path, app.showNewTabButton);

    this.showBrowserBarWrapper(app);
  }

  public downloadRepository(input: IDownloadRepository) {
    let ssnFix = false;
    return new Observable((subject) => {
      this.ultraos.game.downloadRepository(
        input.repositoryId,
        input.branch,
        input.parentRepositoryId,
        input.action,
        input.accessToken,
        (result) => {
          const info = JSON.parse(result.info);
          const progress = Math.round(info.progress * 100);
          if (progress === 100 && !ssnFix) {
            ssnFix = true;
            return;
          }
          progress === 100 ? subject.complete() : subject.next({ progress, downloadSpeed: info.remoteReadSpeed });
        },
      );
    });
  }

  public uninstallRepository(input: IDownloadRepository) {
    let ssnFix = false;
    return new Observable((subject) => {
      this.ultraos.game.downloadRepository(
        input.repositoryId,
        input.branch,
        input.parentRepositoryId,
        input.action,
        input.accessToken,
        (result) => {
          const info = JSON.parse(result.info);
          const progress = Math.round(info.progress * 100);
          if (progress === 100 && !ssnFix) {
            ssnFix = true;
            return;
          }
          // TODO uncomment when fix will be available
          // progress === 100 ? subject.complete() : subject.next({ progress, downloadSpeed: info.remoteReadSpeed });
        },
      );
      // TODO workaround until client fix will be done
      setTimeout(() => {
        subject.next();
        subject.complete();
      }, 500);
    });
  }

  attachToDownloadRepository(repositoryId: string, branch: string, parentRepositoryId: string) {
    return new Observable((subject) => {
      let ssnFix = false;
      this.ultraos.game.attachToDownloadRepositoryProgress(repositoryId, branch, parentRepositoryId, (result) => {
        const info = JSON.parse(result.info);
        const progress = Math.round(info.progress * 100);
        if (progress === 100 && !ssnFix) {
          ssnFix = true;
          return;
        }
        progress === 100 ? subject.complete() : subject.next({ progress, downloadSpeed: info.remoteReadSpeed });
      });
    });
  }

  displayDefaultPage() {
    this.ultraos.displayDefaultPage();
  }

  displayUltraOsLayout() {
    this.ultraos.displayUltraOsLayout();
  }

  isUltraOsLayoutActive(): Observable<boolean> {
    return new Observable((subject) => {
      this.ultraos.isUltraOsLayoutActive((state: boolean) => {
        subject.next(state);
        subject.complete();
      });
    });
  }

  getSetting(key: UltraosSettingKey): Observable<any> {
    return new Observable((subject) => {
      this.ultraos.settings.get(key, (result) => {
        subject.next(result);
        subject.complete();
      });
    });
  }

  setSetting(key: UltraosSettingKey, value: any): Observable<any> {
    return new Observable((subject) => {
      this.ultraos.settings.set(key, value, (result) => {
        subject.next(result);
        subject.complete();
      });
    });
  }

  globalStoreAccessible(): boolean {
    return this.ultraos && this.ultraos.storage;
  }

  clientLayoutAvailable(): boolean {
    return (
      typeof this.ultraos?.displayUltraOsLayout === 'function' &&
      typeof this.ultraos?.displayDefaultPage === 'function' &&
      typeof this.ultraos?.isUltraOsLayoutActive === 'function'
    );
  }

  toaster(title: string, message: string, type: 'success' | 'danger' | 'info' | 'warning'): void {
    if (this.ultraos && this.ultraos.toaster) {
      // todo CHAPTER GP-21278 most probably dead code
      this.ultraos.toaster(title, message, type);
    }
  }

  initStorageEventListener(key: string): Observable<StorageEvent> {
    return new Observable((subject) => {
      this.ultraos.storage.addListener(key, (result: StorageEvent) => {
        subject.next(result);
        return false;
      });
    });
  }

  getDeviceId(): Observable<string> {
    if (!this.ultraos || !this.ultraos.getDeviceID) {
      const encoder = new TextEncoder();
      const keys = [navigator.userAgent, navigator.language, new Date().getTimezoneOffset()];
      const hash = 'ULTRA' + encoder.encode(keys.join('')).reduce((sum, next) => (sum += next), 4321);
      return of(hash);
    }

    return new Observable((subject) => {
      this.ultraos.getDeviceID((deviceID: string) => {
        subject.next(deviceID);
        subject.complete();
      });
    });
  }

  /**
   * Starts the sign-in process in the default browser
   */
  startSignIn(): Promise<void> {
    return this.ultraos.startSignIn();
  }

  /**
   * Starts the sign-up process in the default browser
   */
  startSignUp(): Promise<void> {
    return this.ultraos.startSignUp();
  }

  public getDimensions(): Observable<IDimension[]> {
    return new Observable((subject) => {
      this.ultraos.getDimensionsFile((value) => {
        const result = JSON.parse(value);
        if (result && result.length && result.length > 0) {
          subject.next(result);
          subject.complete();
        } else {
          subject.error();
        }
      });
    });
  }

  public setLicenceCentralGateway(url: string): void {
    this.ultraos.itemsDrm.setGatewayUrl(url);
  }

  public getLicenceCentralGateway(): Observable<string> {
    return new Observable((subject) => {
      this.ultraos.itemsDrm.getGatewayUrl((url) => {
        subject.next(url);
        subject.complete();
      });
    });
  }

  public activateLicense(ticketIds: string[], requestId = ''): Observable<LicenseResponse> {
    return new Observable((subject) => {
      this.ultraos.itemsDrm.activate(ticketIds, requestId, (result) => {
        subject.next(result);
        subject.complete();
      });
    });
  }

  /**
   * Send request to deactivate license to Wibu.
   * @param ticketIds of the licenses to deactivate
   * @returns status of the deactivation.
   */
  public deactivateLicense(ticketIds: string[], requestId = ''): Observable<LicenseResponse> {
    return new Observable((subject) => {
      this.ultraos.itemsDrm.deactivate(ticketIds, requestId, (result) => {
        subject.next(result);
        subject.complete();
      });
    });
  }
  /**
   * Sets up a routine which would renew all already allocated on the current PC licenses from specified tickets every certain time.
   *
   * @example
   * Expiration time is today 16:00, timeDeltaInHours is 6 hours.
   * so renew will happen today at 10:00. and then repeatedly again on 6h before every new expiration.
   * it repeats untill client closed. then setupRenewRoutine should be used again on client start.
   *
   * Details on timer:
   * if closest expiration time has already gone renew would start right away.
   * then repeating timer would be set up for next renewals.
   *
   * @example
   * Every time when it is timeDeltaInHours before closest expiration time all specified licenses will be renewed.
   * if closest time is not passed yet: client sets up repeating renew routine to renew specified licenses each time it is timeDeltaInHours before closest expiration time.
   *
   * timer goes on and on until client restarts after which setupRenewRoutine should be called again.
   * Right now client cannot store TicketIds which are required for renewal thats why renew should be set up on every client launch.
   * For repeating timer there is a threshold of 4h set up. In case resulting routine set to repeat in less then 4h routine is canceled.
   * when setupRenewRoutine called timer set up by previus call is discarded.
   *
   * @param timeDeltaInHours Specifies at which time before closest expiration time specified licenses should be renewed.
   */
  public setupRenewRoutine(ticketIds: string[], requestId = '', timeDeltaInHours = 44): Observable<void> {
    return new Observable((subject) => {
      this.ultraos.itemsDrm.setupRenewRoutine(ticketIds, requestId, timeDeltaInHours, () => {
        subject.next();
        subject.complete();
      });
    });
  }
  /**
   * Returns the list of those tickets that has unactivated licences.
   * If list is empty all tickets are activated.
   *
   * If user does not have a container created on his PC, this endpoint will return status false.
   * @example
   * ```
   * ultraos.itemsDrm.checkLicenseActivation(["JWMPD-VZWXL-38WQC-62AYQ-KYJ2J"]
   *
   * {
   *    response:["JWMPD-VZWXL-38WQC-62AYQ-KYJ2J"],
   *    status: true,
   *    status_message: "result code: 0 errorText:  internalErrorCode:  internalErrorText: "
   * }
   * ```
   */
  public checkLicenseActivation(ticketIds: string[] = []): Observable<LicenseActivationResponse> {
    if (ticketIds.length === 0) {
      return of({
        status: true,
        status_message: 'No tickets to check',
        response: [],
      });
    }
    return new Observable((subject) => {
      this.ultraos.itemsDrm.checkLicenseActivation(ticketIds, (result) => {
        if (result.status) {
          subject.next(result);
          subject.complete();
        } else {
          subject.error();
        }
      });
    });
  }

  public subscribeToRenewRoutineEvents(): Observable<RenewRoutineEventResponse> {
    return new Observable((subscriber) => {
      this.ultraos.itemsDrm.subscribeToRenewRoutineEvents((response: RenewRoutineEventResponse) => {
        subscriber.next(response);
      });
    });
  }

  public unsubscribeFromRenewRoutineEvents(): void {
    this.ultraos.itemsDrm.unsubscribeFromRenewRoutineEvents();
  }

  private getStorageObject(type: StorageType) {
    switch (type) {
      case 'session':
        return this.ultraos.sessionStorage;
      case 'persistent':
      default:
        return this.ultraos.storage;
    }
  }

  get isElectronClient(): boolean {
    return this.ultraos?.isElectronClient;
  }

  get openApp() {
    return this.ultraos.openApp;
  }
}
