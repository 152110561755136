import { CURRENCY_LIST_QUERY } from '../gql/currencies/queries/currency-list.query';
import { CART_GAMES_QUERY } from '../gql/game';
import { APPROVE_ORDER, CREATE_ORDER, REFRESH_ORDER, REGISTER_CAPTCHA } from '../gql/order/mutations/order.mutation';
import { GET_ORDER_QUERY } from '../gql/order/queries/order.query';
import { ORDER_STATUS_SUBSCRIPTION } from '../gql/order/subscriptions/order.subscription';
import { EXCHANGE_MARGIN_QUERY, EXCHANGE_RATE_QUERY, EXCHANGE_RATE_SUBSCRIPTION, STATES_QUERY } from '../gql/other';
import {
  GET_FULL_UNIQ_QUERY,
  GET_UNIQ_FOR_SALE_QUERY,
  GET_UNIQ_QUERY,
  INVENTORY_LIST_FILTER_QUERY,
  INVENTORY_LIST_QUERY,
  UNIQ_FOR_SALE_FEE_QUERY,
} from '../gql/uniq';
import { USER_GAME_QUERY } from '../gql/user-game';
import { UNIQ_FACTORIES_QUERY, UNIQ_FACTORY_WITH_REVENUE_QUERY, UNIQ_SALE_FEE_QUERY } from '../uniq-factory/graphql';

export class ApiSharedGqlConfig {
  // ********** Queries **********
  static readonly INVENTORY_LIST = INVENTORY_LIST_QUERY;
  static readonly UNIQ_FOR_SALE_FEE = UNIQ_FOR_SALE_FEE_QUERY;
  static readonly INVENTORY_LIST_FILTER = INVENTORY_LIST_FILTER_QUERY;
  static readonly UNIQ_BY_ID = GET_FULL_UNIQ_QUERY;
  static readonly GET_UNIQ_QUERY = GET_UNIQ_QUERY;

  static readonly STATE_LIST = STATES_QUERY;
  static readonly CART_GAMES = CART_GAMES_QUERY;

  static readonly CURRENCY_LIST = CURRENCY_LIST_QUERY;
  static readonly EXCHANGE_MARGIN = EXCHANGE_MARGIN_QUERY;
  static readonly EXCHANGE_RATE = EXCHANGE_RATE_QUERY;
  static readonly EXCHANGE_RATE_SUB = EXCHANGE_RATE_SUBSCRIPTION;
  static readonly USER_LIBRARY_GAMES = USER_GAME_QUERY;
  static readonly UNIQ_FACTORIES_QUERY = UNIQ_FACTORIES_QUERY;
  static readonly UNIQ_FACTORY_WITH_REVENUE = UNIQ_FACTORY_WITH_REVENUE_QUERY;
  static readonly UNIQ_SALE_FEE = UNIQ_SALE_FEE_QUERY;
  static readonly GET_UNIQ_FOR_SALE = GET_UNIQ_FOR_SALE_QUERY;

  static readonly GET_ORDER = GET_ORDER_QUERY;
  static readonly CREATE_ORDER = CREATE_ORDER;
  static readonly REFRESH_ORDER = REFRESH_ORDER;
  static readonly APPROVE_ORDER = APPROVE_ORDER;
  static readonly REGISTER_CAPTCHA = REGISTER_CAPTCHA;
  static readonly ORDER_STATUS_SUB = ORDER_STATUS_SUBSCRIPTION;
}
